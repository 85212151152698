<template>
  <button
    type="button"
    class="button"
    :class="{ 'button--secondary': secondary, 'button--disabled': disabled }"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    secondary: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
};
</script>

<style lang="sass" scoped>
.button
  background-color: var(--primary)
  padding: 0.75rem 3.5rem
  border-radius: 0.25rem
  color: white
  font-size: 0.875rem

  &--secondary
    background-color: rgba(74,98,212,0.2)
    padding: 0.75rem 3.5rem
    border-radius: 0.25rem
    color: #242424
    font-size: 0.875rem

  &--disabled
    background-color: #E5E7EB
    color: gray
    cursor: not-allowed
</style>
