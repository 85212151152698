<template>
  <GoogleMapLoader :apiKey="apiKey" :mapConfig="mapOptions">
    <template slot-scope="{ google, map }">
      <CameraMapMarker
        v-for="camera in project.cameras"
        :key="camera.id"
        :camera="camera"
        :google="google"
        :map="map"
        @click="handleMarkerClick"
      />

      <CameraMapInfoWindow
        v-if="selectedCamera"
        :camera="selectedCamera"
        :show="showInfoWindow"
        :google="google"
        :map="map"
        @click="visitCameraHome(selectedCamera)"
      />
    </template>
  </GoogleMapLoader>
</template>

<script>
import config from '@/config';
import GoogleMapLoader from '@/components/GoogleMapLoader';
import CameraMapMarker from '@/components/CameraMapMarker';
import CameraMapInfoWindow from '@/components/CameraMapInfoWindow';

export default {
  props: {
    project: { type: Object, required: true },
  },
  components: {
    GoogleMapLoader,
    CameraMapMarker,
    CameraMapInfoWindow,
  },
  data() {
    return {
      apiKey: config.GOOGLE_MAPS_API_KEY,
      mapOptions: {
        center: {
          lat: this.project.latitude,
          lng: this.project.longitude,
        },
        zoom: 19,
        mapTypeId: 'satellite',
        disableDefaultUI: true,
        zoomControl: true,
      },
      selectedCamera: null,
      showInfoWindow: false,
    };
  },
  methods: {
    handleMarkerClick({ camera }) {
      if (this.selectedCamera?.id == camera.id) {
        this.showInfoWindow = !this.showInfoWindow;
      } else {
        this.selectedCamera = camera;
        this.showInfoWindow = true;
      }
    },

    visitCameraHome(camera) {
      this.$router.push({ name: 'camera-home', params: { id: camera.id } });
    },
  },
};
</script>
