var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("GoogleMapLoader", {
    attrs: { apiKey: _vm.apiKey, mapConfig: _vm.mapOptions },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var google = ref.google
          var map = ref.map
          return [
            _vm._l(_vm.project.cameras, function(camera) {
              return _c("CameraMapMarker", {
                key: camera.id,
                attrs: { camera: camera, google: google, map: map },
                on: { click: _vm.handleMarkerClick }
              })
            }),
            _vm.selectedCamera
              ? _c("CameraMapInfoWindow", {
                  attrs: {
                    camera: _vm.selectedCamera,
                    show: _vm.showInfoWindow,
                    google: google,
                    map: map
                  },
                  on: {
                    click: function($event) {
                      return _vm.visitCameraHome(_vm.selectedCamera)
                    }
                  }
                })
              : _vm._e()
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }