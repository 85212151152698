var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%", width: "100%" } },
    [
      _c("div", { ref: "googleMap", staticClass: "google-map" }),
      Boolean(this.google) && Boolean(this.map)
        ? [_vm._t("default", null, { google: _vm.google, map: _vm.map })]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }