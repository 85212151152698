<template>
  <div style="height: 100%; width: 100%;">
    <div class="google-map" ref="googleMap"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot :google="google" :map="map"></slot>
    </template>
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';

export default {
  props: {
    apiKey: { type: String, required: true },
    mapConfig: {
      type: Object,
      default: () => ({ zoom: 8 }),
    },
  },
  data() {
    return {
      google: null,
      map: null,
    };
  },
  methods: {
    initializeMap() {
      this.google = window.google;
      this.map = new this.google.maps.Map(this.$refs.googleMap, this.mapConfig);
    },
  },
  mounted() {
    const loader = new Loader({ apiKey: this.apiKey, version: 'weekly' });
    loader
      .load()
      .then(this.initializeMap)
      .catch((error) => {
        console.error('Error al cargar Google Maps.', error);
      });
  },
};
</script>

<style>
.google-map {
  width: 100%;
  min-height: 100%;
}
</style>
