<template>
  <AppLayout @cambio="changeLenguage">
    <component :is="viewComponentName" :project="project" />

    <template v-slot:actionbar>
      <div class="d-flex align-items-center">
        <button
          v-if="projects.length > 1"
          @click="$router.push({ name: 'project-list' })"
          class="back-button"
        >
          <svg fill="none" stroke="currentColor" height="24" width="24" viewBox="0 0 24 24">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            ></path>
          </svg>
        </button>

        <TenantLogo class="d-none d-md-block" />

        <div
          class="d-none d-md-block ml-2 mr-2 ml-md-4 mr-md-4"
          style="height: 60px; width: 2px; background-color: #e9e9e9;"
        ></div>

        <div class="d-flex">
          <Logo :image-url="projectLogoUrl" :description="project.name" />

          <div class="d-none d-md-block ml-3">
            <h3 class="title">{{ project.name }}</h3>
            <p class="subtitle">{{$t('lang.select_camera.msg')}}</p>
           

          </div>
        </div>

        <b-form-group class="ml-2 ml-md-4 mb-0">
          <b-form-radio-group
            id="btn-radios-1"
            v-model="viewMode"
            :options="viewOptions"
            buttons
            button-variant="outline-primary"
            name="radios-btn-default"
          ></b-form-radio-group>
        </b-form-group>
        
      </div>
    </template>
  </AppLayout>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import store from '@/store';
import CamerasDefaultView from '@/components/CamerasDefaultView.vue';
import CamerasMapView from '@/components/CamerasMapView.vue';
import CamerasReportView from '@/components/CamerasReportView.vue';
import Logo from '@/components/Logo.vue';
import TenantLogo from '@/components/TenantLogo.vue';

export default {
  name: 'ProjectHome',
  components: {
    CamerasDefaultView,
    CamerasMapView,
    CamerasReportView,
    Logo,
    TenantLogo,
    
  },
  props: {
    project: { type: Object, required: true },
    view: { type: String, default: 'default' },
  },
  data() {
    return {
      viewMode: this.view,
      viewOptions: [
        { text: '', value: 'default' },
        { text: '', value: 'map' },
        { text: '', value: 'report' },
      ],
    };
  },
  created(){
    this.viewOptions[0].text = this.$t('lang.camera.msg')
    this.viewOptions[1].text = this.$t('lang.map.msg')
    this.viewOptions[2].text = this.$t('lang.report.msg')
  },
  methods:{
    changeLenguage(event){
      this.viewOptions[0].text = this.$t('lang.camera.msg')
      this.viewOptions[1].text = this.$t('lang.map.msg')
      this.viewOptions[2].text = this.$t('lang.report.msg')

    }
  },
  computed: {
    viewComponentName() {
      return `cameras-${this.viewMode}-view`;
    },
    ...mapState('project', ['projects']),
    ...mapGetters('project', ['projectLogoUrl']),
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const project = await store.dispatch('project/fetchProject', to.params.id);
      document.title = `${project.name} - Timelapse México`;
      to.params.project = project;
      next();
    } catch (error) {
      if (error.response && error.response.status == 404) {
        next({ name: '404', params: { resource: 'proyecto' } });
      } else {
        next({ name: 'network-issue' });
      }
    }
  },
};
</script>

<style>
.back-button {
  background: none;
  border: none;
  color: #484848;
}
.back-button:focus {
  outline: none;
}
</style>
