<template>
  <GoogleMapMarker :google="google" :map="map" :options="options" @click="handleMarkerClick" />
</template>

<script>
import GoogleMapMarker from '@/components/GoogleMapMarker';

export default {
  name: 'CameraMapMarker',
  components: {
    GoogleMapMarker,
  },
  props: {
    google: { type: Object, required: true },
    map: { type: Object, required: true },
    camera: { type: Object, required: true },
  },
  computed: {
    options() {
      return {
        position: {
          lat: this.camera.latitude,
          lng: this.camera.longitude,
        },
        icon: POINT_MARKER_ICON_CONFIG,
      };
    },
  },
  methods: {
    handleMarkerClick(marker) {
      this.$emit('click', { camera: this.camera, marker });
    },
  },
};

const POINT_MARKER_ICON_CONFIG = {
  path: 'M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0',
  strokeOpacity: 1,
  strokeWeight: 5,
  strokeColor: '#FFFFFF',
  fillColor: '#4A62D4',
  fillOpacity: 1,
  scale: 3,
};
</script>
