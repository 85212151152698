<script>
export default {
  name: 'GoogleMapMarker',
  props: {
    google: { type: Object, required: true },
    map: { type: Object, required: true },
    options: { type: Object, required: true },
  },
  data() {
    return {
      marker: null,
    };
  },
  mounted() {
    const { Marker } = this.google.maps;

    this.marker = new Marker({
      ...this.options,
      map: this.map,
    });

    this.marker.addListener('click', () => {
      this.$emit('click', this.marker);
    });
  },
  beforeDestroy() {
    this.marker.setMap(null);
    this.google.maps.event.clearInstanceListeners(this.marker);
    this.marker = null;
  },
  render() {
    return null;
  },
};
</script>
