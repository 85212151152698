<template>
  <div class="camera-grid">
    <div class="camera" v-for="camera in timelapseCameras" :key="camera.id">
      <img class="camera__cover" :src="cameraCoverUrl(camera)" :alt="camera.name" />
      <div class="camera__overlay">
        <router-link class="camera__link" :to="{ name: 'camera-home', params: { id: camera.id } }">
          <span class="camera__label">{{$t('lang.see.msg')}} {{ camera.name }}</span>
        </router-link>
      </div>
    </div>
  </div>

  <!-- <b-row v-for="(chunk, index) in cameraChunks" :key="index">
      <b-col v-for="camera in chunk" :key="camera.id">
        <div
          class="camera-cover"
          :style="`background-image: url(/img/${camera.last_capture});`"
        ></div>
      </b-col>
    </b-row> -->
</template>

<script>
import _chunk from 'lodash/chunk';

export default {
  props: {
    project: { type: Object, required: true },
  },
  computed: {
    cameraChunks() {
      return _chunk(this.project.cameras, 2);
    },
    timelapseCameras() {
      return this.project.cameras.filter((camera) => camera.show_in_grid);
    },
  },
  methods: {
    cameraCoverUrl(camera) {
      // if (camera.type === 'third-party') {
      //   return camera.cover_image_url ?? '/img/placeholder-ip-camera-2.jpg';
      // }
      // return camera?.last_photo?.optimized_url ?? '/img/photo-placeholder.jpg';

      if(camera.cover_image_url){
        return camera.cover_image_url;
      }else if (camera?.last_photo?.optimized_url){
        return camera?.last_photo?.optimized_url;
      }else if (camera.type === 'third-party') {
        return camera.cover_image_url ?? '/img/placeholder-ip-camera-2.jpg';
      }else{
        return '/img/photo-placeholder.jpg';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.camera-grid {
  position: relative;
  overflow-y: scroll;
  height: 100%;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
  }
}

.camera {
  background-color: #e4e4e7;
  background-size: cover;
  background-position: center;
  flex: 1;
  min-width: 15rem;
  position: relative;
  border: 0.125rem solid white;

  &:hover {
    .camera__overlay {
      opacity: 1;
    }
  }

  &__link {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  &__label {
    background: white;
    padding: 0.625rem 3.5rem;
    border-radius: 0.25rem;
  }
}

.camera__cover {
  width: 100%;
  @include media-breakpoint-up(md) {
    object-fit: cover;
    object-position: center;
    height: 100%;
  }
}

.camera__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(74, 98, 212, 0.3);
  opacity: 0;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-property: all;
}
</style>
