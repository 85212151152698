<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'GoogleMapInfoWindow',
  props: {
    google: { type: Object, required: true },
    map: { type: Object, required: true },
    options: { type: Object, required: true },
    opened: { type: Boolean, default: true },
  },
  data() {
    return {
      infoWindow: null,
    };
  },
  watch: {
    opened: 'handleVisibility',
    options: 'handleVisibility',
  },
  methods: {
    handleVisibility(newValue) {
      this.infoWindow.setOptions(this.options);

      if (newValue) {
        this.infoWindow.open(this.map);
      } else {
        this.infoWindow.close();
      }
    },
  },
  mounted() {
    this.infoWindow = new this.google.maps.InfoWindow({
      ...this.options,
      content: this.$el,
    });

    this.infoWindow.addListener('closeclick', () => {
      this.$emit('closeclick', this.infoWindow);
    });

    if (this.opened) {
      this.infoWindow.open(this.map);
    }
    this.$emit('opened', this.infoWindow);
  },
  beforeDestroy() {
    this.infoWindow.close();
  },
};
</script>
