var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "GoogleMapInfoWindow",
    {
      attrs: {
        opened: _vm.show,
        options: _vm.options,
        google: _vm.google,
        map: _vm.map
      }
    },
    [
      _vm.camera
        ? _c(
            "div",
            {
              staticClass:
                "d-flex flex-column align-items-center justify-content-center"
            },
            [
              _c(
                "p",
                { staticClass: "text-center font-weight-normal camera-name" },
                [_vm._v(_vm._s(_vm.camera.name))]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-2 font-weight-light px-4",
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("click", _vm.camera)
                    }
                  }
                },
                [_c("small", [_vm._v(_vm._s(_vm.$t("lang.continuar.msg")))])]
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }