<template>
  <GoogleMapInfoWindow :opened="show" :options="options" :google="google" :map="map">
    <div v-if="camera" class="d-flex flex-column align-items-center justify-content-center">
      <p class="text-center font-weight-normal camera-name">{{ camera.name }}</p>
      <b-button
        class="mt-2 font-weight-light px-4"
        variant="primary"
        @click="$emit('click', camera)"
      >
        <small>{{$t('lang.continuar.msg')}}</small>
      </b-button>
    </div>
  </GoogleMapInfoWindow>
</template>

<script>
import GoogleMapInfoWindow from '@/components/GoogleMapInfoWindow';

export default {
  name: 'CameraMapInfoWindow',
  props: {
    camera: { type: Object, required: true },
    show: { type: Boolean, default: false },
    google: { type: Object, required: true },
    map: { type: Object, required: true },
  },
  components: {
    GoogleMapInfoWindow,
  },
  computed: {
    options() {
      if (!this.camera) {
        return {};
      }

      return {
        position: {
          lat: this.camera?.latitude,
          lng: this.camera?.longitude,
        },
        pixelOffset: new window.google.maps.Size(0, -20),
      };
    },
  },
};
</script>

<style>
.gm-style-iw-c {
  padding: 0 !important;
  transform: translate(-50%, 70%) !important;
}
.gm-style-iw-t::after {
  transform: translate(-50%, 395%) rotate(135deg) !important;
}
.gm-style-iw-d + button {
  display: none !important;
}
.gm-style-iw-d {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto !important;
}
</style>
