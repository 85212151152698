var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: { open: _vm.show, title: _vm.$t("lang.agregar_reporte.msg") },
          on: { close: _vm.closeModal }
        },
        [
          _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
            _c(
              "h3",
              {
                staticClass: "font-bold text-base",
                attrs: { slot: "header" },
                slot: "header"
              },
              [_vm._v(_vm._s(_vm.$t("lang.agregar_reporte.msg")))]
            ),
            _c(
              "form",
              {
                ref: "formRef",
                attrs: { method: "post", enctype: "multipart/form-data" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.handleSubmit($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "space-y-9" }, [
                  _c("div", { staticClass: "flex space-x-5" }, [
                    _c("label", { staticStyle: { "margin-right": "25px" } }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("lang.video_reporte.msg")) + " "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.type,
                            expression: "type"
                          }
                        ],
                        attrs: { type: "radio", name: "type", value: "video" },
                        domProps: { checked: _vm._q(_vm.type, "video") },
                        on: {
                          change: function($event) {
                            _vm.type = "video"
                          }
                        }
                      })
                    ]),
                    _c("label", [
                      _vm._v(" " + _vm._s(_vm.$t("lang.documento.msg")) + " "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.type,
                            expression: "type"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          name: "type",
                          value: "document"
                        },
                        domProps: { checked: _vm._q(_vm.type, "document") },
                        on: {
                          change: function($event) {
                            _vm.type = "document"
                          }
                        }
                      })
                    ])
                  ]),
                  _c("br"),
                  _c(
                    "div",
                    [
                      _c("BaseInput", {
                        attrs: {
                          label: _vm.$t("lang.titulo_reporte.msg"),
                          name: "title",
                          placeholder: _vm.$t("lang.titulo_reporte.msg"),
                          error: _vm.errors.first("title")
                        },
                        model: {
                          value: _vm.title,
                          callback: function($$v) {
                            _vm.title = $$v
                          },
                          expression: "title"
                        }
                      })
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "div",
                    [
                      _c("BaseInput", {
                        attrs: {
                          label: _vm.$t("lang.descripcion_reporte.msg"),
                          name: "description",
                          type: "textarea",
                          rows: "3",
                          placeholder: _vm.$t("lang.descripcion_reporte.msg"),
                          error: _vm.errors.first("description")
                        },
                        model: {
                          value: _vm.description,
                          callback: function($$v) {
                            _vm.description = $$v
                          },
                          expression: "description"
                        }
                      })
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "div",
                    [
                      _c("datetime", {
                        attrs: {
                          required: "",
                          name: "date",
                          format: "YYYY-MM-DD",
                          error: _vm.errors.first("date")
                        },
                        model: {
                          value: _vm.date,
                          callback: function($$v) {
                            _vm.date = $$v
                          },
                          expression: "date"
                        }
                      })
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "div",
                    [
                      _vm.type === "video"
                        ? _c("BaseInput", {
                            attrs: {
                              label: _vm.$t("lang.contenido.msg"),
                              name: "content",
                              type: "textarea",
                              rows: "5",
                              required: "",
                              placeholder: _vm.$t("lang.contenido.msg"),
                              error: _vm.errors.first("content")
                            },
                            model: {
                              value: _vm.content,
                              callback: function($$v) {
                                _vm.content = $$v
                              },
                              expression: "content"
                            }
                          })
                        : _c("BaseFileInput", {
                            attrs: {
                              accept: "application/pdf",
                              label: "Archivo",
                              name: "document"
                            }
                          })
                    ],
                    1
                  ),
                  _c("br")
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "mt-6 flex items-stretch justify-between space-x-3"
                  },
                  [
                    _c(
                      "BaseButton",
                      {
                        staticClass: "flex-grow py-3",
                        attrs: { secondary: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("close")
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lang.cancelar_reporte.msg")) +
                            " "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "BaseButton",
                      {
                        staticClass:
                          "flex-grow py-3 inline-flex items-center justify-center",
                        attrs: { type: "submit", disabled: _vm.loading }
                      },
                      [
                        _vm.loading
                          ? _c(
                              "svg",
                              {
                                staticClass:
                                  "animate-spin -ml-1 mr-3 h-5 w-5 text-white",
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  fill: "none",
                                  viewBox: "0 0 24 24"
                                }
                              },
                              [
                                _c("circle", {
                                  staticClass: "opacity-25",
                                  attrs: {
                                    cx: "12",
                                    cy: "12",
                                    r: "10",
                                    stroke: "currentColor",
                                    "stroke-width": "4"
                                  }
                                }),
                                _c("path", {
                                  staticClass: "opacity-75",
                                  attrs: {
                                    fill: "currentColor",
                                    d:
                                      "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  }
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm._v(_vm._s(_vm.$t("lang.agregar.msg")) + " ")
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }