var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "camera-grid" },
    _vm._l(_vm.timelapseCameras, function(camera) {
      return _c("div", { key: camera.id, staticClass: "camera" }, [
        _c("img", {
          staticClass: "camera__cover",
          attrs: { src: _vm.cameraCoverUrl(camera), alt: camera.name }
        }),
        _c(
          "div",
          { staticClass: "camera__overlay" },
          [
            _c(
              "router-link",
              {
                staticClass: "camera__link",
                attrs: {
                  to: { name: "camera-home", params: { id: camera.id } }
                }
              },
              [
                _c("span", { staticClass: "camera__label" }, [
                  _vm._v(
                    _vm._s(_vm.$t("lang.see.msg")) + " " + _vm._s(camera.name)
                  )
                ])
              ]
            )
          ],
          1
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }