<template>
  <div>
    
    <Modal :open="show" @close="closeModal" :title="$t('lang.agregar_reporte.msg')">

      <b-overlay :show="loading" rounded="sm">
      <h3 slot="header" class="font-bold text-base">{{ $t('lang.agregar_reporte.msg') }}</h3>

      <form method="post" @submit.prevent="handleSubmit" ref="formRef" enctype="multipart/form-data">
        <div class="space-y-9">
          <div class="flex space-x-5">
            <label style="margin-right: 25px;"> {{ $t('lang.video_reporte.msg') }} <input type="radio" name="type" value="video" v-model="type" /></label>
            <label>
              &nbsp;&nbsp;&nbsp;{{ $t('lang.documento.msg') }} <input type="radio" name="type" value="document" v-model="type" />
            </label>
          </div><br>

          <!-- Title -->
          <div>
            <BaseInput
              :label="$t('lang.titulo_reporte.msg')"
              name="title"
              :placeholder="$t('lang.titulo_reporte.msg')"
              :error="errors.first('title')"
              v-model="title"
            />
          </div>
          <br>

          <!-- Description -->
          <div>
            <BaseInput
              :label="$t('lang.descripcion_reporte.msg')"
              name="description"
              type="textarea"
              rows="3"
              :placeholder="$t('lang.descripcion_reporte.msg')"
              :error="errors.first('description')"
              v-model="description"
            />
          </div>
          <br>

          <!-- Date -->
          <div>
            <datetime
              required
              name="date"
              format="YYYY-MM-DD"
              :error="errors.first('date')"
              v-model="date"
            />
          </div><br>

          <!-- Content -->
          <div>
            <BaseInput
              :label="$t('lang.contenido.msg')"
              name="content"
              type="textarea"
              rows="5"
              required
              :placeholder="$t('lang.contenido.msg')"
              :error="errors.first('content')"
              v-model="content"
              v-if="type === 'video'"
            />

            <BaseFileInput v-else accept="application/pdf" label="Archivo" name="document" />
          </div><br>
        </div>

        <div class="mt-6 flex items-stretch justify-between space-x-3">
          <BaseButton class="flex-grow py-3" secondary @click="$emit('close')">
            {{ $t('lang.cancelar_reporte.msg') }} 
          </BaseButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <BaseButton
            class="flex-grow py-3 inline-flex items-center justify-center"
            type="submit"
            :disabled="loading"
          >
            <svg
              v-if="loading"
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path></svg
            >{{ $t('lang.agregar.msg') }}
          </BaseButton>
        </div>
      </form>
      </b-overlay>
    </Modal>
  </div>
</template>

<script>
import Errors from '@/util/Errors';
import dateUtils from '@/util/date-utils';
import datetime from 'vuejs-datetimepicker';
import BaseButton from '@/components/BaseButton';
import Modal from '@/components/Modal';
import BaseInput from '@/components/BaseInput';
import BaseFileInput from '@/components/BaseFileInput.vue';

export default {
  name: 'ReportCreateModal',
  components: { BaseButton, BaseFileInput, BaseInput, Modal, datetime },
  props: {
    show: { type: Boolean, default: false },
    project: { type: Object, required: true },
  },
  data() {
    return {
      title: '',
      date: dateUtils.formatYMD(new Date()),
      description: '',
      type: 'video',
      content: '',
      loading: false,
      errors: new Errors(),
    };
  },
  methods: {
    closeModal() {
      this.errors.clear();
      this.$emit('close');
      //this.show = false;
    },
    async handleSubmit(event) {
      // const { title, description, date, content, type } = Object.fromEntries(
      //   new FormData(event.target),
      // );
      const formData = new FormData(event.target);
      formData.set('date', new Date(formData.get('date')).toISOString());
      console.log('this.project.id', this.project.id);
      try {
        this.loading = true;
        await this.$store.dispatch('project/addReport', {
          projectId: this.project.id,
          data: formData,
        });

        this.resetForm();
        this.$emit('close');
      } catch (error) {
        console.log(error.response?.data?.message ?? error.message);
      } finally {
        this.loading = false;
      }
    },

    resetForm() {
      this.title = '';
      this.description = '';
      this.type = 'video';
      this.content = '';
      this.date = dateUtils.formatYMD(new Date());
      this.$refs.formRef.reset();
    },
  },
};
</script>
