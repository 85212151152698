var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppLayout",
    {
      on: { cambio: _vm.changeLenguage },
      scopedSlots: _vm._u([
        {
          key: "actionbar",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _vm.projects.length > 1
                    ? _c(
                        "button",
                        {
                          staticClass: "back-button",
                          on: {
                            click: function($event) {
                              return _vm.$router.push({ name: "project-list" })
                            }
                          }
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                fill: "none",
                                stroke: "currentColor",
                                height: "24",
                                width: "24",
                                viewBox: "0 0 24 24"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round",
                                  "stroke-width": "2",
                                  d: "M15 19l-7-7 7-7"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _c("TenantLogo", { staticClass: "d-none d-md-block" }),
                  _c("div", {
                    staticClass: "d-none d-md-block ml-2 mr-2 ml-md-4 mr-md-4",
                    staticStyle: {
                      height: "60px",
                      width: "2px",
                      "background-color": "#e9e9e9"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c("Logo", {
                        attrs: {
                          "image-url": _vm.projectLogoUrl,
                          description: _vm.project.name
                        }
                      }),
                      _c("div", { staticClass: "d-none d-md-block ml-3" }, [
                        _c("h3", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.project.name))
                        ]),
                        _c("p", { staticClass: "subtitle" }, [
                          _vm._v(_vm._s(_vm.$t("lang.select_camera.msg")))
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { staticClass: "ml-2 ml-md-4 mb-0" },
                    [
                      _c("b-form-radio-group", {
                        attrs: {
                          id: "btn-radios-1",
                          options: _vm.viewOptions,
                          buttons: "",
                          "button-variant": "outline-primary",
                          name: "radios-btn-default"
                        },
                        model: {
                          value: _vm.viewMode,
                          callback: function($$v) {
                            _vm.viewMode = $$v
                          },
                          expression: "viewMode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(_vm.viewComponentName, {
        tag: "component",
        attrs: { project: _vm.project }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }