var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reports" },
    [
      _c(
        "div",
        { staticClass: "reports-menu" },
        [
          _c(
            "a",
            {
              staticClass: "reports-menu__item",
              class: {
                "reports-menu__item--active": _vm.activeView === "video"
              },
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.handleShowVideos($event)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lang.videos_reporte.msg")) + " ")]
          ),
          _c(
            "a",
            {
              staticClass: "reports-menu__item",
              class: {
                "reports-menu__item--active": _vm.activeView === "document"
              },
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.handleShowDocuments($event)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lang.documentos.msg")) + " ")]
          ),
          _c(
            "BaseButton",
            {
              staticClass: "h-10 w-40 ml-3",
              on: {
                click: function($event) {
                  _vm.showCreateModal = true
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lang.reporte.msg")) + " ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "reports__container" },
        [
          _c("ReportsView", {
            style: _vm.loading ? "visibility: hidden;" : "",
            attrs: { reports: _vm.reports }
          }),
          _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
            _c("p", [_c("br"), _c("br"), _c("br")])
          ])
        ],
        1
      ),
      _vm.current
        ? _c("ReportCreateModal", {
            attrs: {
              show: _vm.showCreateModal,
              project: _vm.current.data.data
            },
            on: { close: _vm.recargarReportes }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }