var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "button",
      class: {
        "button--secondary": _vm.secondary,
        "button--disabled": _vm.disabled
      },
      attrs: { type: "button", disabled: _vm.disabled },
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }