var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "reports" }, [
    _c(
      "div",
      { staticClass: "reports__main" },
      [
        _vm.selected && _vm.selected.type === "video"
          ? _c("div", {
              key: _vm.keyDocumento,
              staticClass: "reports__content",
              domProps: { innerHTML: _vm._s(_vm.selected.content) }
            })
          : _vm._e(),
        _vm.selected && _vm.selected.type === "document"
          ? _c("div", { staticClass: "reports__content" }, [
              _c("embed", {
                key: _vm.keyDocumento,
                attrs: {
                  title: "report",
                  src: _vm.selected.content,
                  type: "application/pdf",
                  width: "100%",
                  height: "100%",
                  frameborder: "0",
                  allowfullscreen: ""
                }
              })
            ])
          : _vm._e(),
        _vm.reports.length === 0 && !_vm.loading
          ? _c("EmptyIllustration", {
              staticClass: "illustration",
              attrs: {
                title: "Lo sentimos",
                message: "Por el momento no hay reportes disponibles"
              }
            })
          : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "reports__sidebar" }, [
      _c(
        "ul",
        { staticClass: "reports-list" },
        _vm._l(_vm.reports, function(report) {
          return _c("li", { key: report.id }, [
            _c(
              "a",
              {
                staticClass: "reports-list__item",
                class: {
                  "reports-list__item--active":
                    _vm.selected && report.id === _vm.selected.id
                },
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.handleReportSelection(report)
                  }
                }
              },
              [_vm._v(" " + _vm._s(report.title) + " ")]
            )
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }