<template>
  <div class="reports">
    
    <div class="reports-menu">
      <a
        href="#"
        class="reports-menu__item"
        :class="{ 'reports-menu__item--active': activeView === 'video' }"
        @click.prevent="handleShowVideos"
      >
        {{ $t('lang.videos_reporte.msg') }}
      </a>
      <a
        href="#"
        class="reports-menu__item"
        :class="{ 'reports-menu__item--active': activeView === 'document' }"
        @click.prevent="handleShowDocuments"
      >
        {{ $t('lang.documentos.msg') }}
      </a>

      <BaseButton class="h-10 w-40 ml-3" @click="showCreateModal = true">
          {{ $t('lang.reporte.msg') }}
        </BaseButton>
    </div>

    <div class="reports__container">
      <ReportsView :reports="reports"  :style="loading ? `visibility: hidden;` : ''" />

      <b-overlay :show="loading" rounded="sm">
        <p><br><br><br></p>
      </b-overlay>
    </div>


    <ReportCreateModal
      :show="showCreateModal"
      v-if="current"
      @close="recargarReportes"
      :project="current.data.data"
    />

  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton';
import projectService from '@/services/project';
import ReportsView from '@/components/ReportsView.vue';
import ReportCreateModal from '@/components/ReportCreateModal.vue';
export default {
  name: 'CamerasReportView',
  components: { ReportsView, BaseButton, ReportCreateModal },
  props: {
    project: { type: Object, required: true },
  },
  data() {
    return {
      activeView: 'video',
      reports: [],
      loading: true,
      showCreateModal: false,
      current: null,
    };
  },
  methods: {
    async fetchReports(type) {
      try {
        this.loading = true;

        const { data } = await projectService.getReports(this.project.id, type);
        this.reports = data.data;
        this.selected = this.reports[0];
        this.loading = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async handleShowVideos() {
      this.activeView = 'video';
      await this.fetchReports('video');
    },
    async handleShowDocuments() {
      this.activeView = 'document';
      await this.fetchReports('document');
    },

    async recargarReportes(){
      this.showCreateModal = false
      if(this.activeView == 'document'){
        await this.fetchReports('document');
      }else{
        await this.fetchReports('video');
      }
      
    }
  },
  async created() {
    this.loading = true;
    this.current = await projectService.getProject(this.project.id);
    console.log("🚀 ~ fetchReports ~ this.current", this.current)

    this.fetchReports(this.activeView);
  },
};
</script>

<style lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.reports {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  &-menu {
    display: flex;
    text-align: center;
    justify-content: space-evenly;

    &__item {
      display: block;
      padding: 0.5rem 0 0.25rem 0;

      &:hover {
        text-decoration: none;
        color: var(--primary);
      }

      &--active {
        border-bottom: 2px solid var(--primary);
        font-weight: bold;
      }
    }
  }

  &__container {
    flex: 1;
    margin-top: 0.5rem;
  }
}
</style>
