<template>
  <div class="relative block">
    <div
      class="border border-gray-500 overflow-hidden rounded-md input-content transition duration-200 ease-linear"
      :class="{ 'border-primary': focused && !error, 'border-red-600': error }"
    >
      <label
        class="absolute text-xs bg-white p-1 -top-3 left-9 transition duration-200 ease-linear"
        :class="{ 'text-primary': focused && !error, 'text-red-600': error }"
        v-if="label"
      >
        {{ label }}
      </label>

      <div
        class="h-12 border-none text-sm w-full placeholder-gray-400 focus:outline-none focus:ring-0 cursor-pointer flex items-center pl-3"
        @click="handleChooseFile"
      >
        <span>{{ filename }}</span>
      </div>
      <input
        type="file"
        v-bind="$attrs"
        class="hidden"
        ref="fileInputRef"
        @change="handleFileChange"
        @focus="focused = true"
        @blur="focused = false"
      />
    </div>

    <div class="text-sm text-red-600 pl-10 mt-1" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'BaseFileInput',
  inheritAttrs: false,
  props: {
    label: { type: String, default: '' },
    error: String,
  },
  data() {
    return {
      focused: false,
      filename: 'Selecciona un archivo...',
    };
  },
  methods: {
    handleChooseFile() {
      this.$refs.fileInputRef.click();
    },
    handleFileChange(event) {
      this.filename = event.target.files[0]?.name ?? 'Selecciona un archivo...';
    },
  },
};
</script>
