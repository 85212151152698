var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative block" }, [
    _c(
      "div",
      {
        staticClass:
          "border border-gray-500 overflow-hidden rounded-md input-content transition duration-200 ease-linear",
        class: {
          "border-primary": _vm.focused && !_vm.error,
          "border-red-600": _vm.error
        }
      },
      [
        _vm.label
          ? _c(
              "label",
              {
                staticClass:
                  "absolute text-xs bg-white p-1 -top-3 left-9 transition duration-200 ease-linear",
                class: {
                  "text-primary": _vm.focused && !_vm.error,
                  "text-red-600": _vm.error
                }
              },
              [_vm._v(" " + _vm._s(_vm.label) + " ")]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass:
              "h-12 border-none text-sm w-full placeholder-gray-400 focus:outline-none focus:ring-0 cursor-pointer flex items-center pl-3",
            on: { click: _vm.handleChooseFile }
          },
          [_c("span", [_vm._v(_vm._s(_vm.filename))])]
        ),
        _c(
          "input",
          _vm._b(
            {
              ref: "fileInputRef",
              staticClass: "hidden",
              attrs: { type: "file" },
              on: {
                change: _vm.handleFileChange,
                focus: function($event) {
                  _vm.focused = true
                },
                blur: function($event) {
                  _vm.focused = false
                }
              }
            },
            "input",
            _vm.$attrs,
            false
          )
        )
      ]
    ),
    _vm.error
      ? _c("div", { staticClass: "text-sm text-red-600 pl-10 mt-1" }, [
          _vm._v(_vm._s(_vm.error))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }