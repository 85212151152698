<template>
  <div class="reports">
    <div class="reports__main">
      <div
        class="reports__content"
        :key="keyDocumento"
        v-if="selected && selected.type === 'video'"
        v-html="selected.content"
      ></div>
      <div class="reports__content" v-if="selected && selected.type === 'document'">
        <embed
          title="report"
          :src="selected.content"
          :key="keyDocumento"
          type="application/pdf"
          width="100%"
          height="100%"
          frameborder="0"
          allowfullscreen
        />
      </div>

      <EmptyIllustration
        class="illustration"
        title="Lo sentimos"
        message="Por el momento no hay reportes disponibles"
        v-if="reports.length === 0 && !loading"
      />
    </div>

    <div class="reports__sidebar">
      <ul class="reports-list">
        <li v-for="report in reports" :key="report.id">
          <a
            class="reports-list__item"
            :class="{
              'reports-list__item--active': selected && report.id === selected.id,
            }"
            href="#"
            @click.prevent="handleReportSelection(report)"
          >
            {{ report.title }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import EmptyIllustration from '@/components/illustrations/EmptyIllustration.vue';

export default {
  name: 'ReportsView',
  components: { EmptyIllustration },
  props: {
    reports: Array,
  },
  data() {
    return {
      selected: null,
      loading: false,
      keyDocumento:2323,
    };
  },
  watch: {
    reports: function(newValue) {
      //console.log("🚀 ~ file: ReportsView.vue ~ line 67 ~ newValue", newValue)
      this.selected = newValue[0] ?? null;
      this.keyDocumento++;
    },
  },
  methods: {
    formattedDate(date) {
      const dateObj = new Date(date);
      return dateObj.toLocaleDateString(undefined, { month: 'long', year: 'numeric' });
    },
    handleReportSelection(report) {
      //console.log("🚀 handleReportSelection ~ report", report)
      this.selected = report;
      this.keyDocumento++;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.reports {
  height: 100%;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row-reverse;
  }

  &__main {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(md) {
      padding: 0 1rem;
    }
  }

  &__sidebar {
    height: 30%;
    overflow-y: scroll;
    box-shadow: 0px -2px 20px 5px rgb(0 0 0 / 15%);

    @include media-breakpoint-up(md) {
      box-shadow: 2px 5px 10px 2px rgb(0 0 0 / 15%);
      height: 100%;
      width: 25%;
      max-width: 15.625rem;
    }
  }

  &-list {
    list-style: none;
    padding: 0;
    margin: 0;

    &__item {
      padding: 1rem;
      display: block;
      text-transform: uppercase;

      &--active {
        background: var(--primary);
        color: white;

        &:hover {
          color: white;
        }
      }
    }
  }
}
.illustration {
  padding: 2rem;

  @include media-breakpoint-up(md) {
    width: 18rem;
  }
}
</style>

<style lang="scss">
.reports__content {
  width: 100%;
  height: 100%;
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
